import React, { useEffect, useState } from 'react';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import DesignedForPrivate from '../designed-for-private';
import { RouteFormatter } from '../../routes';
import { isUnverified } from '../../consts/user.consts';
import { SIGN_UP_SRC } from '../../consts/spot.consts';
import { AUTH_TESTS } from '../../pages/spot-view-page/auth-test.const';
import { Present } from './present';
import './index.scss';

const CookieService = require('../../services/cookie.service');

const ListingPresent = ({
    spotId,
    dogsPrsnt,
    pplePrsnt,
    anmlsPrsnt,
    userDetails,
    describeDogsPresent,
    describeDomesticAnimalsPresent,
    describePeoplePresent,
    describePrivateEntry,
    prvtEntry,
    dogsPercentage,
    otherAnimalsPercentage,
    peoplePercentage,
    useStateSSR,
    serverSSR,
}) => {
    const useStateC = useStateSSR || useState;
    const [showModal, setShowModal] = useStateC({});
    const haveData = dogsPrsnt || pplePrsnt || anmlsPrsnt;

    const toggle = async (present) => {
        const isUnver = isUnverified(userDetails);

        if (!userDetails?.id || isUnver) {
            const re = `${window.location.pathname}#${AUTH_TESTS.DIST_PRIVATE}-${present}`;

            if (isUnver) {
                CookieService.default.set(COOKIE_PARAM_NAME.REDIRECT, window.location.href);
                CookieService.default.set(COOKIE_PARAM_NAME.REDIRECT_ONBOARD, re);
                window.location = RouteFormatter.finishYourAccount();
                return;
            }

            CookieService.default.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, SIGN_UP_SRC.SPOT_DETAILS_WEB, { expires: 1 });
            CookieService.default.set(COOKIE_PARAM_NAME.REDIRECT, re);
            window.location = RouteFormatter.signUp();
            return;
        }

        setShowModal({ [present]: !showModal[present] });
    };

    // guest auth test
    useEffect(() => {
        const urlHash = window.location.hash.substring(1);
        const splitHash = urlHash?.split('-');
        if (userDetails?.id && urlHash && splitHash[0] == AUTH_TESTS.DIST_PRIVATE) {
            const present = splitHash[1];
            setShowModal({ [present]: !showModal[present] });
        }
    }, []);

    if (!haveData) {
        return '';
    }

    return (
        <div className="presents px-2 px-md-0">
            <div className="mt-3">
                <DesignedForPrivate />
            </div>
            <h2 className="snif-m1 snif-semibold mt-2 mb-3">Distractions seen/heard from the spot</h2>
            <div className="row row-field">
                <Present
                    type="dogsPresent"
                    spotId={spotId}
                    present={dogsPrsnt}
                    describe={describeDogsPresent}
                    percentages={dogsPercentage}
                    toggle={toggle}
                    ensurePrivate
                    showModal={showModal['dogsPresent']}
                    serverSSR={serverSSR}
                />
                <Present
                    type="domesticAnimalsPresent"
                    spotId={spotId}
                    present={anmlsPrsnt}
                    describe={describeDomesticAnimalsPresent}
                    percentages={otherAnimalsPercentage}
                    toggle={toggle}
                    ensurePrivate
                    showModal={showModal['domesticAnimalsPresent']}
                    serverSSR={serverSSR}
                />
                <Present
                    type="peoplePresent"
                    spotId={spotId}
                    present={pplePrsnt}
                    describe={describePeoplePresent}
                    percentages={peoplePercentage}
                    toggle={toggle}
                    ensurePrivate
                    showModal={showModal['peoplePresent']}
                    serverSSR={serverSSR}
                />
                {prvtEntry && (
                    <Present
                        type="prvtEntry"
                        present={prvtEntry}
                        describe={describePrivateEntry}
                        percentages={null}
                        toggle={toggle}
                        ensurePrivate
                        showModal={showModal['prvtEntry']}
                        serverSSR={serverSSR}
                    />
                )}
            </div>
            <hr />
        </div>
    );
};

export default ListingPresent;
